import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import AgentImage from "../../../assets/roberto-nickson-bwIqQ5qQhXs-unsplash.webp";
import LenderCard from "../../../components/LenderResultCard";
import {
  getLenderByCity,
  getLenderByCityAndName,
  getLenderByName,
  getLenderNames,
  getPremiumLenders,
  getLenderFullNames,
  getLenderByCityAndFullName,
} from "../../../utils/api";
import PlacesAutocomplete from "../../../components/PlacesAutocomplete";

const Search = () => {
  const [agents, setAgents] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const premiumAgentsFirst = (data) => {
    const premiumAgents = data.filter((agent) => agent.isPremium === true);
    const notpremiumAgents = data.filter((agent) => agent.isPremium === false);

    const complete = premiumAgents.concat(notpremiumAgents);
    return complete;
  };

  // Pagination
  const itemsPerPage = 9;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(agents.slice(itemOffset, endOffset));
    // console.log(agents.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(agents.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, agents]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % agents.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  // Search Input
  const [selectedName, setselectedName] = useState("");
  const [formattedAddress, setFormattedAddress] = React.useState("");
  const [showNameDropdown, setShowNameDropdown] = useState(false);
  const [names, setNames] = useState([]);
  const [filteredNames, setfilteredNames] = useState([]);
  const [isFullName, setIsFullName] = useState(false);

  /** Name Input Functions */
  // Remove the Drop-down when a user selects on an option
  const handleNameInputSelect = (name) => {
    setselectedName(name);
    setNames([]);
    setfilteredNames([]);
    setShowNameDropdown(false);
    setIsFullName(true);
  };
  const filterName = (selectedName) => {
    const filtered = names.filter((name) =>
      name.toLowerCase().startsWith(selectedName.toLowerCase())
    );
    setfilteredNames(filtered);
  };
  // Triggered when the user types in the Input field
  const handleNameInputChange = (event) => {
    const selectedName = event.target.value;
    setselectedName(selectedName);
    localStorage.removeItem("agentSearchTerm");
    setIsFullName(false);
    if (selectedName.trim() === "") {
      setShowNameDropdown(false);
      setNames([]);
    } else if (selectedName.length === 1) {
      // Fetch Name Names from db
      getLenderNames(selectedName.trim())
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);

          setNames(data);
          setfilteredNames(data);
          setShowNameDropdown(true);
        })
        .catch((err) => console.log(err));
    } else if (selectedName.length > 1) {
      filterName(selectedName);
    }
  };

  // Handle Autcomplete options based on key input
  const handleNameKeyDown = (e) => {
    const action = e.keyCode;

    switch (action) {
      case 40:
        // Down arrow (Scroll down)
        if (!selectedName) {
          setselectedName(filteredNames[0]);
        } else {
          const index = filteredNames.indexOf(selectedName);
          const increment = index + 1;
          return !filteredNames[increment]
            ? null
            : setselectedName(filteredNames[increment]);
        }
        break;
      case 38:
        // Up arrow (Scroll up)
        if (!selectedName) {
          return;
        } else {
          const index = filteredNames.indexOf(selectedName);
          const decrimate = index - 1;
          return !filteredNames[decrimate]
            ? setselectedName("")
            : setselectedName(filteredNames[decrimate]);
        }
      case 13:
        // Return (Select)
        setselectedName(selectedName);
        setNames([]);
        setfilteredNames([]);
        setIsFullName(true);
        break;
      default:
        return;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    let x = 0;
    if (formattedAddress.trim() !== "") {
      x = x + 1;
    }
    if (selectedName.trim() !== "") {
      x = x + 2;
    }

    switch (x) {
      case 0:
        break;
      case 1:
        // Search by City
        getLenderByCity(formattedAddress.trim())
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            const premiumFirst = premiumAgentsFirst(data.filterCities);
            setAgents(premiumFirst);
            setIsFeatured(false);
          })
          .catch((err) => console.log(err));
        break;
      case 2:
        // Search by Name
        if (isFullName === true) {
          getLenderFullNames(selectedName.trim())
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              const premiumFirst = premiumAgentsFirst(data);
              setAgents(premiumFirst);
              setIsFeatured(false);
            })
            .catch((err) => console.log(err));
        } else {
          getLenderByName(selectedName.trim())
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              const premiumFirst = premiumAgentsFirst(data);
              setAgents(premiumFirst);
              setIsFeatured(false);
            })
            .catch((err) => console.log(err));
        }
        break;
      case 3:
        // Search by City and Name
        if (isFullName === true) {
          getLenderByCityAndFullName(
            formattedAddress.trim(),
            selectedName.trim()
          )
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              const premiumFirst = premiumAgentsFirst(data.filterCities);
              setAgents(premiumFirst);
              setIsFeatured(false);
            })
            .catch((err) => console.log(err));
        } else {
          getLenderByCityAndName(formattedAddress.trim(), selectedName.trim())
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              const premiumFirst = premiumAgentsFirst(data.filterCities);
              setAgents(premiumFirst);
              setIsFeatured(false);
            })
            .catch((err) => console.log(err));
        }

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Get Premium Agents on page load
    getPremiumLenders()
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        setAgents(data);
        setIsFeatured(true);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <Header />

        <div className="min-height"></div>
        <Footer />
      </>
    );
  }

  return (
    <div>
      <Header />

      {/* Page Header/Searchbar */}
      <div className="agent-search-header">
        <img
          className="agent-search-image"
          src={AgentImage}
          alt="A welcoming front porch with chaires and a plant."
        />
        <div className="agent-search-header-bg lender-search-header-bg">
          <h1>Search Find A Home Lenders</h1>

          <div className="agent-search-form lender-search-form">
            <div className="input-container">
              <div>
                <PlacesAutocomplete
                  setFormattedAddress={setFormattedAddress}
                  formattedAddress={formattedAddress}
                />
              </div>
            </div>
            <label htmlFor="city" className="hidden">
              City
            </label>
            <div className="input-container">
              <input
                className="agent-search-input"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={selectedName}
                onChange={handleNameInputChange}
                onKeyDown={(e) => {
                  handleNameKeyDown(e);
                }}
                autoComplete="off"
              />
              {showNameDropdown && (
                <div className="dropdown-items">
                  {filteredNames.map((name) => (
                    <div
                      key={name}
                      className={
                        name === selectedName
                          ? "dropdown-item highlight"
                          : "dropdown-item"
                      }
                      onClick={() => handleNameInputSelect(name)}
                    >
                      <p>{name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <label htmlFor="name" className="hidden">
              Name
            </label>
            <button
              className="agent-search-btn text-shadow"
              onClick={handleSearch}
            >
              Search Now
            </button>
          </div>
        </div>
      </div>

      <main className="agent-search-results">
        <h2>
          {/* <b>
            {agents.length} {isFeatured === true ? "Featured" : ""}
          </b>{" "} */}
          Featured Lenders
        </h2>

        {/* Result Containr */}
        <Items currentItems={currentItems} />

        {/* Pagination Buttons */}
        <div className="pagination-container">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </main>

      <Footer />
    </div>
  );
};

const Items = ({ currentItems }) => {
  return (
    <div className="agent-search-results-container">
      {currentItems !== null &&
        currentItems.map((item) => <LenderCard key={item.id} agent={item} />)}
    </div>
  );
};

export default Search;
