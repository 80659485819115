import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./home.css";
import buyImage from "../../assets/buy.webp";
import sellImage from "../../assets/sell.webp";
import agentImage from "../../assets/find-an-agent.webp";
import lenderImage from "../../assets/lender.webp";

const Home = () => {
  return (
    <>
      <Header />

      {/* HERO */}
      <section>
        <div className="search-container">
          <h3> Next Stop, Home. </h3>
          <div className="search-button-container">
            <Link to={"/search"} className="search-btn-home text-shadow">
              Search Now
            </Link>
          </div>
        </div>
      </section>

      {/* Cards */}
      <section className="home-card-container">
        <a href="/search">
          <div className="home-card">
            <img
              src={buyImage}
              alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
              width={"320px"}
              height={"222px"}
            />
            <div className="home-card-text">
              <h2>Find a home?</h2>
              <p>
                Your real estate needs are unique to you. Discover your dream
                home with our immersive photo gallery and an extensive range of
                listings.
              </p>

              <button>Browse homes</button>
            </div>
          </div>
        </a>

        <a href="/find-an-agent">
          <div className="home-card">
            <img
              src={agentImage}
              alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
              width={"320px"}
              height={"222px"}
            />
            <div className="home-card-text">
              <h2>Find an agent</h2>
              <p>
                If you’re looking to rent, buy, or sell your home, find a local
                agent who can guide you through the process from start to
                finish.
              </p>

              <button>View agents</button>
            </div>
          </div>
        </a>

        <a href="/find-a-lender">
          <div className="home-card">
            <img
              src={lenderImage}
              alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
              width={"320px"}
              height={"222px"}
            />
            <div className="home-card-text">
              <h2>Find a lender</h2>
              <p>
                Ready to buy a new home or refinance your mortgage? Get in touch
                with a skilled professional who can support you every step of
                the way.
              </p>

              <button>View lenders</button>
            </div>
          </div>
        </a>

        <a href="https://findahome.hifello.com/lp/654aa58ad4c524002518bb15">
          <div className="home-card card-2">
            <img
              src={sellImage}
              alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
              width={"320px"}
              height={"222px"}
            />
            <div className="home-card-text">
              <h2>Sell a home</h2>
              <p>
                No matter what approach you choose to sell your home, we are
                here to guide you towards a successful sale.
              </p>

              <button>See your options</button>
            </div>
          </div>
        </a>
      </section>

      <Footer />
    </>
  );
};

export default Home;
