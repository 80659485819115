import "./style.css";
import check from "../../../assets/check.png";

const PricingCards = ({
  handleFree,
  handleStandard,
  handlePro,
  handleEnterprise,
}) => {
  return (
    <div className="pricing-mobile">
      <div className="grid-3">
        {/* Free Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Free</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$0</p>
                    <p className="pricing-conditions ">Per Month</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Essential tools for staying connected, building referrals,
                    and keeping skills sharp with monthly trainings.
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>Directory profile section on Find a Home/Busca Tu Casa</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Referral Network</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Marketing Center</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Monthly Zoom Trainings</td>
                <td>
                  <div className="check-container">
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFree();
                    }}
                  >
                    Get Started
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Standard Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Standard</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$89</p>
                    <p className="pricing-conditions">Per Month</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Enhanced visibility and mobile access for agents ready to
                    boost their reach and client engagement.<br></br>
                    <br></br>Includes everything in the Free tier plus:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>Mobile App Access</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Featured Website Profile</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleStandard();
                    }}
                  >
                    Buy Now
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Pro Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Pro</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$197</p>
                    <p className="pricing-conditions">Per Month</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Streamlined CRM integration designed for solo agents or team
                    leaders aiming for efficient client management.<br></br>
                    <br></br>Includes everything in the Standard tier plus:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>
                  Mobile App to CRM Integration - Team Lead or Solo Agent (FUB &
                  Sierra Only)
                </td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Enhanced Marketing</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePro();
                    }}
                  >
                    Buy Now
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Enterprise Card */}
        <div className="pricing-container">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="pricing-th mobile">
                  <h2>Enterprise</h2>
                  <div className="mobile-div">
                    <p className="pricing-price">$197</p>
                    <p className="pricing-conditions">Per Month</p>
                  </div>
                  <hr></hr>
                  <p className="pricing-description mobile">
                    Custom solutions tailored for teams and brokerages seeking a
                    branded experience to elevate their business.<br></br>
                    <br></br>Includes everything in the Pro tier plus:
                  </p>
                </th>
              </tr>
            </thead>

            {/* Features */}
            <tbody>
              <tr>
                <td>Custom App Build for Teams and Brokerages</td>
                <td>
                  <div className="check-container">
                    {" "}
                    <img src={check} alt="Check" />
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <button
                    className="pricing-signup-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEnterprise();
                    }}
                  >
                    Request Demo
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
